@import "mixins"
.accountBonusesFooter
  position: sticky
  bottom: var(--menuHeight)
  background: var(--body_bg)

.footerDiv
  @include flex
  margin-top: 2px
  @include bold
  background: var(--account_betsItemBg)

  > div
    padding: 0 10px
    height: 40px
    @include flex

    &:first-child
      min-width: 140px
      max-width: 140px
      justify-content: flex-end
      color: #a9b5c9
      border-right: 1px solid var(--body_bg)
      padding-left: 4px

    &:last-child
      color: #fff
      padding-right: 4px

      > span:not(:first-of-type)
        margin-left: 4px

.getBonus
  background: #27ae60
  color: #fff
  text-transform: uppercase
  margin-top: 4px
  width: 100%
  margin-bottom: 12px
  position: relative

  &:active
    background: #1d9550

  > span
    position: absolute
    right: 8px
    top: 50%
    transform: translateY(-50%)

    path
      fill: #fff

  &.paid
    background: rgba(#27ae60, 0.4)
    pointer-events: none
    padding: 0 22px

  &.getBonusDisabled
    background: rgba(#dfe6f3, 0.3)
    color: rgba(#21262c, 0.7)
    pointer-events: none
