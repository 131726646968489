@import "mixins"
.bonusItem
  display: flex
  flex-direction: column
  padding: 1px
  background: var(--account_betsItemBorder)
  border-radius: 3px
  margin-bottom: 10px
  > div
    @include flex
    &:last-of-type
      background: var(--account_bonusItemRowBg)
      > div
        flex: 1
        display: flex
        justify-content: center
        flex-direction: column
        height: 42px
        padding: 0 8px
        color: #fff
        .label
          color: rgb(var(--primaryColor))
          margin-bottom: 4px
      > div:nth-child(2)
        background: var(--account_transactionItemRowAmountBg)
        border-right: 1px solid var(--account_betsItemBorder)
        border-left: 1px solid var(--account_betsItemBorder)
.header
  background: var(--account_betsItemBg)
  height: 32px
  padding: 0 8px
  @include bold
  text-transform: uppercase
  border-top-left-radius: 3px
  border-top-right-radius: 3px
  margin-bottom: 1px
  color: #fff
  > span
    margin-right: 10px
  svg path:not(:global(.sport-icon-bg))
    fill: #fff

  strong
    margin-left: auto
    color: rgb(var(--thirdaryColor))
